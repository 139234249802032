<template>
    <div class="wrap">
        <div class="nav_bar">
            <van-icon name="arrow-left" size="20" style="position: absolute;left: 18px;top: 24px;"
                @click="onClickLeft" />
            <span style="font-size: 20px;color:#323233; font-weight: bold;">待处理危急值</span>
        </div>
        <div class="wrap_list">
            <div class="time">{{info.resultDateTime}}</div>
            <div class="name_box">
                <span class="name">{{info.name}}</span>
                <!-- <div class="level">危急IV</div> -->
            </div>
            <div class="list_content">患者编号：{{info.patientId}}</div>
            <div class="list_content">信息：{{info.wardDeptName}}、{{info.bedNo}}</div>
            <div class="list_content" style="line-height: 20px;">病情：{{info.reportItemName}} {{info.result}} {{info.units}}（{{info.lowerCrisisValue}}-{{info.upperCrisisValue}}）</div>
        </div>
        <div class="content">
            <div class="content_lable">
                <div>项目名称</div>
                <div style="margin-left: 20px;">测定结果</div>
                <div>单位</div>
                <!-- <div>状态</div> -->
            </div>
            <!-- <div class="content_list" v-for="(item, i) in 5" :key="i">
                <div>
                    <div style="max-width:120px">
                        嗜酸粒绝对值
                    </div>
                    <div style="font-size:13px;color:#999;margin-top:10px; font-weight: 500;">
                        参考范围：30.5-98.9
                    </div>
                </div>
                <div style="margin-left: -30px;">34</div>
                <div style="margin-left: 10px;">L/s</div>
                <div style="color:#F12622;margin-right: 10px; ">+</div>
            </div> -->
            <div class="content_list" :key="i">
                <div>
                    <div style="max-width:120px">
                        {{info.wardDeptName}}
                    </div>
                    <div style="font-size:13px;color:#999;margin-top:10px; font-weight: 500;">
                        参考范围：{{info.lowerCrisisValue}}-{{info.upperCrisisValue}}
                    </div>
                </div>
                <div>{{info.result}}</div>
                <div>{{info.units}}</div>
                <!-- <div style="color:#F12622;margin-right: 10px; ">+</div> -->
            </div>
            <!-- <div class="warning">
                <van-icon name="warning" />K>7 mmol/L，已经构成危险值。建议进行处理
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            value: '',
            info:{}
        }
    },
    created(){
    //   this.info = JSON.parse(this.$route.params.item)
      this.info = JSON.parse(this.$route.query.item)
    },
    methods: {
        onClickLeft() {
            // this.$router.push({ path: '/menu' })
            this.$router.back()
        },
        onClickInfo() {
            this.$router.push({ path: '/criticalInfo' })
        }
    }
}
</script>
<style lang="less" scoped>
.wrap {
    background: #fff;
    min-height: 100vh;
    margin-bottom: -50px !important;
    padding-bottom: 10px;

    .nav_bar {
        text-align: center;
        color: #323233;
        background-color: #fff;
        padding-top: 20px;

    }

    .wrap_list {
        background-color: #fff;
        border-radius: 5px;
        padding: 30px 15px 20px 15px;

        .time {
            font-size: 16px;
            color: #333;
            font-weight: bold;
        }

        .name_box {
            display: flex;
            align-items: center;
            margin-top: 15px;

            .name {
                font-size: 19px;
                font-weight: bold;
                color: #333;
            }

            .level {
                width: 49px;
                height: 22px;
                text-align: center;
                line-height: 22px;
                color: #fff;
                background: #F12622;
                border-radius: 4px;
                font-size: 13px;
                font-weight: bold;
                margin-left: 30px;
            }
        }

        .list_content {
            font-size: 13px;
            color: #666;
            margin-top: 8px;
        }

        .detail {
            color: #1578F9;
            font-size: 16px;
            text-align: right;
            font-weight: bold;
        }
    }

    .content {
        margin: 0px 15px 45px 15px;

        .content_lable {
            background: #F4F2F2;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            color: #666;
            padding: 13px 18px;
            // font-weight: bold;
        }

        .content_list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 13px 18px;
            border-bottom: 1px dashed #E7E7E7;
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.van-icon-warning {
    color: #F12622;
    font-size: 14px;
    margin-right: 8px;
}
.warning {
    color: #F12622;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-top: 25px;
}
</style>